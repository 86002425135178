import React from "react";
import { Dialog } from "primereact/dialog";
import classes from "./Dialog.module.css";

const evenColor = "#fff";
const oddColor = "#f2f2f2";
const containerStyle = {
  fontSize: "1rem",
  lineHeight: "1.4",
};
const listItemStyle = {
  padding: "8px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
};
const labelStyle = {
  fontWeight: "bold",
};
const evenStyle = {
  ...listItemStyle,
  backgroundColor: evenColor,
};
const oddStyle = {
  ...listItemStyle,
  backgroundColor: oddColor,
};

export default function EventPopup({ visible, onHide, eventInfo }) {
  const {
    id,
    title,
    time,
    location,
    description,
    startDate,
    endDate,
    sportType,
    eventType,
    eventStatus,
    eventOrg,
    eventAddress,
    eventPartDeadline,
    eventOrgHead,
    reqDocuments,
  } = eventInfo;

  const formattedEventInfo = [
    { label: "Дата и время", value: time },
    { label: "Местоположение", value: location },
    { label: "Описание", value: description },
    { label: "Дата начала", value: startDate },
    { label: "Дата окончания", value: endDate },
    { label: "Вид спорта", value: sportType },
    { label: "Тип мероприятия", value: eventType },
    { label: "Статус мероприятия", value: eventStatus },
    { label: "Организатор", value: eventOrg },
    { label: "Адрес мероприятия", value: eventAddress },
    { label: "Дедлайн принятия заявок", value: eventPartDeadline },
    { label: "Ответственное лицо организатора", value: eventOrgHead },
    { label: "Требуемые документы", value: reqDocuments },
  ];

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={title}
      style={{ width: "80%" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
    >
      <ul style={containerStyle}>
        {formattedEventInfo.map((item, index) => (
          <li key={index} style={index % 2 === 0 ? evenStyle : oddStyle}>
            <span style={labelStyle}>{item.label}:</span>
            {item.value}
          </li>
        ))}
      </ul>
      <a
        href={`https://org.moisport.ru/public-events-schedule/${id}`}
        target="_blank"
        rel="noreferrer"
      >
        <button className={classes.Button}>Подать заявку</button>
      </a>
    </Dialog>
  );
}
