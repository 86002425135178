// App.js
import React from "react";
import Layout from "./Hoc/Layout/Layout";
import { PrimeReactProvider } from "primereact/api";
import { Helmet } from "react-helmet";
function App() {
  return (
    <PrimeReactProvider>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type
          of="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Layout />
    </PrimeReactProvider>
  );
}

export default App;
