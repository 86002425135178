// ResultsPage.js
import React from "react";

function ResultsPage() {
  return (
    <div>
      <h1>Результаты спортивных мероприятий</h1>
      {/* Добавьте контент для страницы результатов */}
    </div>
  );
}

export default ResultsPage;
