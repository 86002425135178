import React, { useState, useEffect } from "react";
import classes from "./Calendar.module.css";
import EventFilters from "../../components/Filters/Filters";
import banner from "../../assets/banner.png";
import Card from "../../components/Card/Card";
import { Paginator } from "primereact/paginator";
import calendarData from "../../assets/calendar/calendar.json";
import localeData from "../../assets/locale/locale.json";
import categoriesEventId from "../../assets/calendar/sportsman_categories_event_id.json";

import { locale, addLocale } from "primereact/api";
addLocale("ru", localeData.ru);
locale("ru");

function CalendarPage() {
  const eventsData = calendarData.events;
  const [filteredEvents, setFilteredEvents] = useState(eventsData);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(12); // Количество карточек на одной странице

  const onPaginationChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  // const [locationParam, setLocationParam] = useState(null);
  useEffect(() => {}, []);
  const filterEvents = (filters) => {
    let ageOptions;

    if (filters.age != null) {
      ageOptions = [
        ...new Set(
          categoriesEventId
            .filter((cat_event) => cat_event.category_type === filters.age)
            .map((event) => event.event_id)
        ),
      ];
    }

    setFilteredEvents(
      eventsData.filter((event) => {
        return (
          (!filters.sport_type || event.sport_type === filters.sport_type) &&
          (!filters.event_type || event.event_type === filters.event_type) &&
          (!filters.event_status ||
            event.event_status === filters.event_status) &&
          (ageOptions ? ageOptions.includes(event.id) : true) &&
          (!filters.event_region ||
            event.event_region === filters.event_region) &&
          (!filters.start_date || isDateInRange(event, filters.start_date))
        );
      })
    );
  };

  // Функция для проверки, находится ли дата в диапазоне
  const isDateInRange = (event, filterDate) => {
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);
    const filter = new Date(filterDate);
    return filter >= startDate && filter <= endDate;
  };
  return (
    <div className={classes.Calendar}>
      <img className={classes.calendarImage} alt="main banner" src={banner} />
      <EventFilters
        // initialLocation={locationParam}
        onFilterChange={filterEvents}
        events={eventsData}
      />
      <div className={classes.CardWrapper}>
        {filteredEvents.slice(first, first + rows).map((event) => (
          <Card
            id={event.id}
            mainImg={event.mainImg}
            time={event.start_date}
            location={event.event_region}
            title={event.event_name}
            description={event.additional_info}
            startDate={event.start_date}
            endDate={event.end_date}
            sportType={event.sport_type}
            eventType={event.event_type}
            eventStatus={event.event_status}
            eventOrg={event.event_org}
            eventAddress={event.event_address}
            eventPartDeadline={event.event_part_deadline}
            eventOrgHead={event.event_org_head}
            reqDocuments={event.req_documents}
            provisions={event.url}
          />
        ))}
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={filteredEvents.length}
        onPageChange={onPaginationChange}
        rowsPerPageOptions={[6, 12, 24]} // Настройте опции количества карточек на странице
      />
    </div>
  );
}

export default CalendarPage;
